import _forEach from 'lodash/forEach';
import { useBlocksFromPage } from '../../../../stores/InterfaceStore';
import { useRegistryContext } from '../../../RegistryProvider';

export default function usePreloadBlocks() {
  const blocks = useBlocksFromPage();
  const { registry } = useRegistryContext();

  _forEach(blocks, (block) => {
    registry.preloadBlock(block.component.componentType);
  });
}
