import { useInterfaceStore } from '../../stores/InterfaceStore';
import type { InterfaceResourceProviderProps } from '../InterfaceResourceProvider/types';
import useSetLocationInStore from './hooks/useSetLocationInStore';
import useCollectDataSourcesOutputSchemas from './hooks/useCollectDataSourcesOutputSchemas';
import useHandleOnPageOutputChange from './hooks/useHandleOnOutputChange';
import useSetUserInStore from './hooks/useSetUserInStore';
import usePreloadBlocks from './hooks/usePreloadBlocks';
import useSetPageVariablesValues from './hooks/useSetPageVariablesValues';

function SideEffectsInStore({
  children,
  onOutputChange,
}: {
  children: React.ReactNode;
  onOutputChange: InterfaceResourceProviderProps['onOutputChange'];
}) {
  const mode = useInterfaceStore().use.mode();
  useSetLocationInStore();
  useCollectDataSourcesOutputSchemas(mode);
  useSetUserInStore();
  useHandleOnPageOutputChange(onOutputChange);
  usePreloadBlocks();
  useSetPageVariablesValues();

  return <>{children}</>;
}

export default SideEffectsInStore;
