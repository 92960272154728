import { useEffect, useMemo } from 'react';
import { nanoid } from 'nanoid';

const CLEANUP_EVENT = '__CLEANUP__';

export const useInjectHTML = (html: string | undefined, target: 'head' | 'body' = 'body') => {
  // Create a unique cleanup event name for each instance of the hook, as we do not want
  // cleanup listeners of one instance to interfere with another instance
  const cleanupEventName = useMemo(() => `${CLEANUP_EVENT}${nanoid()}`, []);

  useEffect(() => {
    if (!html) {
      return;
    }

    const container = document.createElement('div');
    container.innerHTML = html.trim();

    const targetElement = target === 'head' ? document.head : document.body;
    // Storing elements to be removed when the component unmounts
    const elements: ChildNode[] = [];

    while (container.firstChild) {
      const element = container.firstChild;

      if (element.nodeName.toLowerCase() === 'script') {
        // Browser doesn't execute script when added via innerHTML, so we need to dynamically create script element and add to dom
        const scriptElement = document.createElement('script');

        // Copy text content and replace `CLEANUP_EVENT` listener with the unique cleanup event name
        scriptElement.textContent =
          element.textContent?.replace(new RegExp(CLEANUP_EVENT, 'g'), cleanupEventName) ?? '';

        if (element instanceof HTMLElement) {
          // Copy all attributes
          Array.from(element.attributes).forEach((attr) => {
            scriptElement.setAttribute(attr.name, attr.value);
          });
        }

        targetElement.appendChild(scriptElement);
        elements.push(scriptElement);
        element.remove();
      } else {
        targetElement.appendChild(element);
        elements.push(element);
      }
    }

    return () => {
      window.dispatchEvent(new CustomEvent(cleanupEventName));
      elements.forEach((element) => {
        element.remove();
      });
    };
  }, [cleanupEventName, html, target]);
};
