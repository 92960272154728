import _find from 'lodash/find';
import { invariant } from 'ts-invariant';
import type { Action } from '@unifyapps/defs/types/action';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { useSnackbar } from '@unifyapps/ui/components/Snackbar';
import { getComputedData } from '../../../../utils/getComputedData';
import { useRegistryContext } from '../../../../components/RegistryProvider';
import type {
  ActionHandlerType,
  OnActionArgs,
} from '../../../../components/ActionsProvider/context';
import {
  useGetGlobalStateStoreState,
  useGlobalStateStore,
} from '../../../../stores/GlobalStateStore';
import { useGetInterfaceStoreState } from '../../../../stores/InterfaceStore';

type ControlComponentPayloadType = {
  blockId: string;
  methodName: string;
  methodPayload: Record<string, unknown>;
};

function useControlBlockMethod() {
  const { showSnackbar, closeSnackbar } = useSnackbar();

  const { registry } = useRegistryContext();
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const { getInterfaceStoreState } = useGetInterfaceStoreState();
  const { setBlockState } = useGlobalStateStore().use.actions();

  const onCallBlockMethod = useEventCallback(
    async ({
      actionContext,
      blockId,
      action,
      methodName,
    }: {
      blockId: string;
      methodName: string;
      action: Action;
      actionContext: OnActionArgs['actionContext'];
    }) => {
      const blocksState = getGlobalStateStoreState().blocks;
      const activeInterfacePageId = getInterfaceStoreState().activeInterfacePageId;
      const interfacePages = getInterfaceStoreState().interfacePages;
      const blocks = interfacePages[activeInterfacePageId].properties.blocks;

      const computedAction = getComputedData<Action>(
        action as unknown as Record<string, unknown>,
        actionContext,
      );

      const blockRefs = getGlobalStateStoreState().blockRefs;

      const block = _find(blocks, { id: blockId });

      invariant(block, `Block with id ${blockId} not found`);

      const controlMethods = registry.getBlockControlMethods(block.component.componentType, block);
      const method = _find(controlMethods, { id: methodName })?.method;

      if (method) {
        const blockState = blocksState[blockId];
        invariant(blockState, `Component with block id ${blockId} not found`);
        const nextBlockState = await method(
          blockState,
          computedAction,
          blockRefs[blockId]?.current,
          { showSnackbar, closeSnackbar },
        );
        if (nextBlockState) {
          setBlockState(blockId, nextBlockState);
        }
      }
      return Promise.resolve();
    },
  );

  const onControlBlockMethod: ActionHandlerType = useEventCallback(
    async ({ action, actionContext }: OnActionArgs) => {
      const payload = action.payload as ControlComponentPayloadType;

      await onCallBlockMethod({
        blockId: payload.blockId,
        methodName: payload.methodName,
        actionContext,
        action,
      });
    },
  );

  return { onControlBlockMethod, onCallBlockMethod };
}

export default useControlBlockMethod;
