import type { DataSource } from '@unifyapps/defs/types/dataSource';
import _values from 'lodash/values';
import { useEffect, useMemo } from 'react';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import type { QueryRequestResult } from '../../../stores/GlobalStateStore';
import useEvents from '../../../hooks/useBlockEvents';
import type { QueryNode } from '../types';

//useQuery callbacks has been discontinued
//https://github.com/TanStack/query/discussions/5279
// so executing callback in useEffect
export function useExecuteDataSourceCallbacks({
  callbacks,
  queryResult,
  onQueryCompletion,
  requestId,
  id,
}: {
  callbacks: DataSource['callbacks'];
  onQueryCompletion: QueryNode['onQueryCompletion'];
  requestId: string;
  id: string;
  queryResult: Partial<QueryRequestResult>;
}) {
  const { onSuccessEvents, onFailureEvents } = useMemo(() => {
    return {
      onSuccessEvents: _values(callbacks?.onSuccess),
      onFailureEvents: _values(callbacks?.onFailure),
    };
  }, [callbacks?.onFailure, callbacks?.onSuccess]);

  const { emitEvent: emitSuccessEvent } = useEvents(onSuccessEvents);
  const { emitEvent: emitErrorEvent } = useEvents(onFailureEvents);

  const onSuccess = useEventCallback(() => {
    void emitSuccessEvent({ eventType: EventTypeEnum.OnSuccess });
  });

  const onError = useEventCallback(() => {
    void emitErrorEvent({ eventType: EventTypeEnum.OnFailure });
  });

  useEffect(() => {
    requestAnimationFrame(() => {
      if (queryResult.isLoading || queryResult.isFetching) {
        return;
      }

      // handle error or success based on query result
      // and then call the onQueryCompletion callback
      if (queryResult.error) {
        onError();
      } else {
        onSuccess();
      }

      onQueryCompletion({
        requestId,
        id,
      });
    });
  }, [
    id,
    onError,
    onQueryCompletion,
    onSuccess,
    queryResult.error,
    queryResult.isFetching,
    queryResult.isLoading,
    requestId,
  ]);
}
