import React, { useMemo } from 'react';
import { useAsync } from 'react-use';
import { UppyContext } from '../../hooks/useUppy';
import { getBaseUrl } from './BaseUrl/getBaseUrl';

const getUppyLib = async () => {
  const getUppyInstance = await import('../../hooks/useUppy/getUppyInstance');

  return { getUppyInstance };
};

export const useUppyLazyGetInstance = () => {
  const { value, loading } = useAsync(getUppyLib, []);

  return { getUppyInstance: value?.getUppyInstance.default, isReady: !loading };
};

export default function UppyProvider({
  userId,
  children,
}: {
  userId?: string;
  children: React.ReactNode;
}) {
  const { getUppyInstance, isReady } = useUppyLazyGetInstance();

  // reinitialize uppy on user refetch
  const uppyInstance = useMemo(
    () => {
      const baseUrl: string = getBaseUrl(isReady);
      return isReady
        ? {
            loading: false,
            Uppy:
              getUppyInstance?.(
                `${baseUrl}/api-uploads`,
                {},
                // request,
              ) ?? null,
          }
        : { loading: true, Uppy: null };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only reinitialize on user refetch
    [userId, getUppyInstance],
  );

  return <UppyContext.Provider value={uppyInstance}>{children}</UppyContext.Provider>;
}
