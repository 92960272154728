import { removeDataBindingBraces } from '../../../../utils/dynamicBindings';
import type { OnActionArgs } from '../../../../components/ActionsProvider/context';
import { indirectExecuteScript } from '../../../../utils/indirectEval';

export const useExecuteScript = () => {
  const onExecuteScript = ({ action, actionContext }: OnActionArgs) => {
    const script = action.payload?.script as string | undefined;

    try {
      if (script) {
        indirectExecuteScript(removeDataBindingBraces(script), actionContext);
      }
    } catch (err) {
      console.error('Error evaluating script: ', err);
    }

    return Promise.resolve();
  };

  return { onExecuteScript };
};
