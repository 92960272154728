/* eslint-disable @typescript-eslint/no-unsafe-assignment -- since we are using keys */
import { produce } from 'immer';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import type { ExecuteWorkflowNodeRequest } from '@unifyapps/network/generated/models/executeWorkflowNodeRequest';
import type { ExecuteWorkflowNodeResponse } from '@unifyapps/network/generated/models/executeWorkflowNodeResponse';
import type { ExecuteWorkflowNodeResponseLookupReferences } from '@unifyapps/network/generated/models/executeWorkflowNodeResponseLookupReferences';
import { UAApps } from '@unifyapps/defs/types/apps';

const wildcard = '[*].';

interface Params {
  data: Record<string, unknown>;
  path: string;
  name: string;
  lookupReferences: ExecuteWorkflowNodeResponseLookupReferences;
}

const mutateDataWithLookupReferences = (params: Params) => {
  const { data, path, name, lookupReferences } = params;

  const parentPath = path.split('.').slice(0, -1).join('.');
  const parentObject = parentPath ? (get(data, parentPath) as Record<string, unknown>) : data;
  const value = get(data, path) as string | undefined;

  if (typeof parentObject !== 'object' || !value) {
    return;
  }

  const lookupValue = get(lookupReferences, [name, value]);

  if (lookupValue) {
    parentObject[name] = lookupValue;
  }
};

export const adaptExecuteWorkflowNodeResponse = (
  data?: ExecuteWorkflowNodeResponse,
  lookupRequests?: ExecuteWorkflowNodeRequest['lookupRequests'],
  appName?: UAApps,
) => {
  if (appName === UAApps.PlatformByUA) {
    return data;
  }

  if (!data?.response || !lookupRequests?.length || isEmpty(data.lookupReferences)) {
    return data?.response;
  }

  const lookupReferences = data.lookupReferences;

  return produce(data.response, (draft) => {
    lookupRequests.forEach((lookupRequest) => {
      const name = lookupRequest.name;
      // @ts-expect-error -- should be present when lookupRequest is byKeys
      let keys = lookupRequest.keys;

      if (Array.isArray(keys)) {
        // backward compatibility for old lookupRequests - earlier it was a string
        keys = keys[0];
      }

      if (!name || typeof keys !== 'string') {
        return;
      }

      const hasWildcard = keys.includes(wildcard);

      if (hasWildcard) {
        const [list, path] = keys.split(wildcard);

        if (!list || !path) {
          return;
        }

        const listData = get(draft, list);

        if (!Array.isArray(listData)) {
          return;
        }

        listData.forEach((item) => {
          mutateDataWithLookupReferences({ data: item, path, name, lookupReferences });
        });
      } else {
        mutateDataWithLookupReferences({ data: draft, path: keys, name, lookupReferences });
      }
    });
  });
};
