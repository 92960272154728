/* eslint-disable no-await-in-loop -- need to execute sequentially */
import type { EmitActionParams } from '@unifyapps/defs/hooks/useBlockAction';
import type { TriggerEventParams } from '@unifyapps/defs/hooks/useBlockEvents';
import type { Action } from '@unifyapps/defs/types/action';
import type { Event } from '@unifyapps/defs/types/event';
import type { Dispatch, SetStateAction } from 'react';
import { logError } from '../../utils/error-reporting/log';
import type { OnActionType } from '../components/ActionsProvider/context';
import { getEventsToTrigger } from './utils/getEventsToTrigger';

export const BLOCK_TARGET_ID = 'BASE_BLOCK';
class NoCodeEventHandler {
  // {
  //   '<targetId>': {
  //     <eventType> : [{
  //       ...event
  //     }]
  //   }
  // }
  private events: Record<string, Record<string, Event[] | undefined> | undefined>;
  private processing: Record<string, Record<string, boolean> | undefined>;
  private eventQueues: Record<string, Record<string, TriggerEventParams[]>>;
  private doAction: OnActionType;
  private getComputedFilters: (props: object, context: Record<string, unknown>) => unknown;
  private getComputeContext: () => Record<string, unknown>;
  private setToggleState: Dispatch<SetStateAction<boolean>>;

  constructor({
    getComputedFilters,
    events,
    getComputeContext,
    setToggleState,
  }: {
    getComputedFilters: (props: object, context: Record<string, unknown>) => unknown;
    getComputeContext: () => Record<string, unknown>;
    events: Event[];
    setToggleState: Dispatch<SetStateAction<boolean>>;
  }) {
    this.events = {};
    this.eventQueues = {};
    this.processing = {};
    this.getComputedFilters = getComputedFilters;
    this.getComputeContext = getComputeContext;
    this.setToggleState = setToggleState;
    this.registerEvents(events);
    this.registerQueue();
  }

  registerQueue() {
    const allTargetIds = [...Object.keys(this.events), BLOCK_TARGET_ID];
    for (const targetId of allTargetIds) {
      this.registerQueueEventType(targetId);
    }
  }

  registerQueueEventType(targetId: string) {
    this.eventQueues[targetId] = {};
    const eventTypes = Object.keys(this.events[targetId] || {});
    for (const eventType of eventTypes) {
      this.eventQueues[targetId][eventType] = [];
    }
  }

  registerEvents(events: Event[]) {
    for (const event of events) {
      this.registerEvent(event);
    }
  }

  registerEvent(event: Event) {
    const eventType = event.eventType;
    const targetId = event.targetId || BLOCK_TARGET_ID;
    this.events = {
      ...this.events,
      [targetId]: {
        ...this.events[targetId],
        [eventType]: [...(this.events[targetId]?.[eventType] || []), event],
      },
    };
    this.setEventToProcessing(eventType, targetId, false);
  }

  getEventsByType(
    eventType: string,
    targetId: string,
    actionContext: EmitActionParams['actionContext'],
  ) {
    const events = this.events[targetId]?.[eventType] || [];

    return getEventsToTrigger({
      events,
      actionContext,
      eventType,
    });
  }

  setEventToProcessing(eventType: string, targetId: string, processing: boolean) {
    this.processing = {
      ...this.processing,
      [targetId]: {
        ...this.processing[targetId],
        [eventType]: processing,
      },
    };
    this.setToggleState((prev) => !prev);
  }

  triggerEvent = async (params: TriggerEventParams) => {
    const { eventType, doAction, actionContext } = params;
    const targetId = params.targetId || BLOCK_TARGET_ID;
    const events = this.getEventsByType(eventType, targetId, actionContext);
    if (!events.length) {
      // console.error(`Event ${eventType} not registered.`);
      return;
    }

    this.eventQueues[targetId][eventType].push(params);

    if (!this.processing[targetId]?.[eventType]) {
      await this.processEventQueue(targetId, eventType, doAction);
    }
  };

  private async processEventQueue(targetId: string, eventType: string, doAction: OnActionType) {
    try {
      this.setEventToProcessing(eventType, targetId, true);
      this.doAction = doAction;

      while (this.eventQueues[targetId][eventType].length > 0) {
        const params = this.eventQueues[targetId][eventType].shift();
        if (params) await this.executeEvent(params);
      }
    } catch (error) {
      logError(error);
    } finally {
      this.setEventToProcessing(eventType, targetId, false);

      // Check if there are any new events in the queue
      if (this.eventQueues[targetId][eventType].length > 0) {
        await this.processEventQueue(targetId, eventType, doAction);
      }
    }
  }

  private async executeEvent(params: TriggerEventParams) {
    const { eventType, actionContext } = params;
    const targetId = params.targetId || BLOCK_TARGET_ID;
    const events = this.getEventsByType(eventType, targetId, actionContext);

    for (const event of events) {
      const { action } = event;

      await this.executeAction({
        action,
        actionContext: params.actionContext,
        domEvent: params.domEvent,
      });
    }
  }

  async executeActions(actions: Action[], emitActionParams: Partial<EmitActionParams>) {
    for (const action of actions) {
      await this.executeAction({
        ...emitActionParams,
        action,
      });
    }
  }

  async executeAction(params: EmitActionParams) {
    const { action, actionContext } = params;
    try {
      const computedContext = this.getComputeContext();

      const { runCondition: shouldRun } = this.getComputedFilters(action, computedContext) as {
        runCondition: boolean | undefined;
      };

      if (shouldRun === false) {
        console.debug(
          'action run condition(determined by filters set in only run when field) not met, skipping event',
        );
        return;
      }

      if (typeof action.delayDuration === 'number') {
        await new Promise((resolve) => {
          setTimeout(resolve, action.delayDuration);
        });
      }

      const computeContext = this.getComputeContext();

      await this.doAction({
        ...params,
        actionContext: {
          ...computeContext,
          ...(typeof actionContext === 'function' ? actionContext(computeContext) : actionContext),
        },
      });

      if (action.onSuccessActions) {
        await this.executeActions(action.onSuccessActions, {
          actionContext: params.actionContext,
          domEvent: params.domEvent,
        });
      }
    } catch (error) {
      console.error(`Error executing action ${params.action.actionType}:`, error);
      if (params.action.onErrorActions) {
        await this.executeActions(params.action.onErrorActions, {
          actionContext: params.actionContext,
          domEvent: params.domEvent,
        });
      }
    }
  }

  getIsProcessingEvent = (targetId: string) => {
    return Object.keys(this.processing[targetId] || {}).some(
      (eventType) => this.processing[targetId]?.[eventType],
    );
  };
}

export default NoCodeEventHandler;
